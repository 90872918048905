import { HamburgerIcon } from "@chakra-ui/icons";
import { Divider, Flex, HStack, Spacer, Stack } from "@chakra-ui/layout";
import { IconButton } from "@chakra-ui/button";
import { useColorModeValue } from "@chakra-ui/color-mode";
import { useDisclosure } from "@chakra-ui/hooks";
import React, { isValidElement } from "react";
import { MobileNavContent } from "./MobileNavContent";

export const Template = (props) => {
  const children = React.Children.toArray(props.children).filter(
    isValidElement
  );
  const mobileNav = useDisclosure();
  return (
    <Flex
      py={1}
      px={{
        base: 4,
        md: 6,
        lg: 8,
      }}
      maxH={"48px"}
      overflow={"hidden"}
      bg={useColorModeValue("#1B2430", "gray.800")}
      boxShadow={useColorModeValue("md", "none")}
      borderBottomWidth={useColorModeValue("none", "1px")}
    >
      {children.find((child) => child.type === Brand)?.props.children}

      <HStack
        spacing={3}
        display={{
          base: "none",
          md: "flex",
        }}
      >
        {children.find((child) => child.type === Links)?.props.children}
      </HStack>
      <Spacer />
      <HStack
        display={{
          base: "none",
          md: "flex",
        }}
        spacing={3}
      >
        {children.find((child) => child.type === UserProfile)?.props.children}
      </HStack>

      <IconButton
        display={{
          base: "flex",
          md: "none",
        }}
        size="sm"
        aria-label="Open menu"
        fontSize="20px"
        onClick={mobileNav.onOpen}
        icon={<HamburgerIcon />}
      />

      <MobileNavContent isOpen={mobileNav.isOpen} onClose={mobileNav.onClose}>
        <Stack spacing={5}>
          <Flex>
            {children.find((child) => child.type === Brand)?.props.children}
          </Flex>
          <Stack>
            {children.find((child) => child.type === Links)?.props.children}
          </Stack>
          <Divider />
          <Flex>
            {
              children.find((child) => child.type === UserProfile)?.props
                .children
            }
          </Flex>
        </Stack>
      </MobileNavContent>
    </Flex>
  );
};

const Brand = () => null;

const Links = () => null;

const UserProfile = () => null;

export const Navbar = Object.assign(Template, {
  Brand,
  Links,
  UserProfile,
});
