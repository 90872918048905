import React, { useState, useEffect, useMemo } from "react";
import { navigateToUrl } from "single-spa";
import { isLoggedIn } from "@defense-station/auth";
import { getUserProfile } from "@defense-station/api-service";
import { Navbar } from "./components/navbar/Navbar";
// import { NavTabLink } from '../components/navbar/NavTabLink';
const UserProfile = React.lazy(() => import("./components/navbar/UserProfile"));
import { ToastContainer } from "react-toastify";
import { Center, Link } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { ChakraProvider } from "@chakra-ui/provider";
import { Image } from "@chakra-ui/image";
import theme from "./theme/theme";

export default function Root(props) {
  const [user, setUser] = useState(null);
  const authenticated = isLoggedIn();
  useEffect(() => {
    if (authenticated)
      getUserProfile().then((res) =>
        setUser(res?.data?.iam_v1_GetAuthInfo?.user)
      );
  }, []);
  const onClickSignIn = () => {
    navigateToUrl("/login");
  };

  const isDsPlus = useMemo(() => props.type == "ds-plus", [props])
  return (
    <ChakraProvider resetCSS theme={theme}>
      <Navbar>
        <Navbar.Brand>
          <Center marginEnd={6}>
            <Link href={isDsPlus?`https://app.${process.env.DS_PLUS_DOMAIN}/`:`https://app.${process.env.DOMAIN}/`} isExternal>
              <Image
                w="auto"
                h="10"
                mx="auto"
                src={require("./assets/logo.png")}
              />
            </Link>
          </Center>
        </Navbar.Brand>
        {authenticated ? (
          <Navbar.UserProfile>
            <React.Suspense fallback={<div></div>}>
              <UserProfile
                key={location.href}
                user={user}
                locationPath={location}
                name={`${user?.first_name} ${user?.last_name}`}
                firstName={user?.first_name}
                lastName={user?.last_name}
                avatarUrl=""
                email={user?.email}
                type={props?.type}
              />
            </React.Suspense>
          </Navbar.UserProfile>
        ) : (
          <Navbar.UserProfile>
            <Button onClick={onClickSignIn}>Sign in</Button>
          </Navbar.UserProfile>
        )}
      </Navbar>
      <ToastContainer />
    </ChakraProvider>
  );
}
